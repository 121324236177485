export const countriesObj = {
  AF: { label: "Afghanistan", calling_code: "+93", currency_code: "AFN" },
  AL: { label: "Albania", calling_code: "+355", currency_code: "ALL" },
  DZ: { label: "Algeria", calling_code: "+213", currency_code: "DZD" },
  AS: { label: "American Samoa", calling_code: "+1684", currency_code: "USD" },
  AD: { label: "Andorra", calling_code: "+376", currency_code: "EUR" },
  AO: { label: "Angola", calling_code: "+244", currency_code: "AOA" },
  AI: { label: "Anguilla", calling_code: "+1264", currency_code: "XCD" },
  AQ: { label: "Antarctica", calling_code: "+672", currency_code: "XCD" },
  AG: {
    label: "Antigua and Barbuda",
    calling_code: "+1268",
    currency_code: "XCD",
  },
  AR: { label: "Argentina", calling_code: "+54", currency_code: "ARS" },
  AM: { label: "Armenia", calling_code: "+374", currency_code: "AMD" },
  AW: { label: "Aruba", calling_code: "+297", currency_code: "AWG" },
  AU: { label: "Australia", calling_code: "+61", currency_code: "AUD" },
  AT: { label: "Austria", calling_code: "+43", currency_code: "EUR" },
  AZ: { label: "Azerbaijan", calling_code: "+994", currency_code: "AZN" },
  BS: { label: "Bahamas", calling_code: "+1242", currency_code: "BSD" },
  BH: { label: "Bahrain", calling_code: "+973", currency_code: "BHD" },
  BD: { label: "Bangladesh", calling_code: "+880", currency_code: "BDT" },
  BB: { label: "Barbados", calling_code: "+1246", currency_code: "BBD" },
  BY: { label: "Belarus", calling_code: "+375", currency_code: "BYR" },
  BE: { label: "Belgium", calling_code: "+32", currency_code: "EUR" },
  BZ: { label: "Belize", calling_code: "+501", currency_code: "BZD" },
  BJ: { label: "Benin", calling_code: "+229", currency_code: "XOF" },
  BM: { label: "Bermuda", calling_code: "+1441", currency_code: "BMD" },
  BT: { label: "Bhutan", calling_code: "+975", currency_code: "BTN" },
  BO: { label: "Bolivia", calling_code: "+591", currency_code: "BOB" },
  BA: {
    label: "Bosnia and Herzegovina",
    calling_code: "+387",
    currency_code: "BAM",
  },
  BW: { label: "Botswana", calling_code: "+267", currency_code: "BWP" },
  BV: { label: "Bouvet Island", calling_code: "+55", currency_code: "NOK" },
  BR: { label: "Brazil", calling_code: "+55", currency_code: "BRL" },
  IO: {
    label: "British Indian Ocean Territory",
    calling_code: "+246",
    currency_code: "USD",
  },
  BN: { label: "Brunei", calling_code: "+673", currency_code: "BND" },
  BG: { label: "Bulgaria", calling_code: "+359", currency_code: "BGN" },
  BF: { label: "Burkina Faso", calling_code: "+226", currency_code: "XOF" },
  BI: { label: "Burundi", calling_code: "+257", currency_code: "BIF" },
  KH: { label: "Cambodia", calling_code: "+855", currency_code: "KHR" },
  CM: { label: "Cameroon", calling_code: "+237", currency_code: "XAF" },
  CA: { label: "Canada", calling_code: "+1", currency_code: "CAD" },
  CV: { label: "Cape Verde", calling_code: "+238", currency_code: "CVE" },
  KY: { label: "Cayman Islands", calling_code: "+1345", currency_code: "KYD" },
  CF: {
    label: "Central African Republic",
    calling_code: "+236",
    currency_code: "XAF",
  },
  TD: { label: "Chad", calling_code: "+235", currency_code: "XAF" },
  CL: { label: "Chile", calling_code: "+56", currency_code: "CLP" },
  CN: { label: "China", calling_code: "+86", currency_code: "CNY" },
  CX: { label: "Christmas Island", calling_code: "+61", currency_code: "AUD" },
  CC: {
    label: "Cocos (Keeling) Islands",
    calling_code: "+61",
    currency_code: "AUD",
  },
  CO: { label: "Colombia", calling_code: "+57", currency_code: "COP" },
  KM: { label: "Comoros", calling_code: "+269", currency_code: "KMF" },
  CG: { label: "Congo", calling_code: "+242", currency_code: "XAF" },
  CK: { label: "Cook Islands", calling_code: "+682", currency_code: "NZD" },
  CR: { label: "Costa Rica", calling_code: "+506", currency_code: "CRC" },
  HR: { label: "Croatia", calling_code: "+385", currency_code: "HRK" },
  CU: { label: "Cuba", calling_code: "+53", currency_code: "CUP" },
  CY: { label: "Cyprus", calling_code: "+357", currency_code: "EUR" },
  CZ: { label: "Czech Republic", calling_code: "+420", currency_code: "CZK" },
  DK: { label: "Denmark", calling_code: "+45", currency_code: "DKK" },
  DJ: { label: "Djibouti", calling_code: "+253", currency_code: "DJF" },
  DM: { label: "Dominica", calling_code: "+1767", currency_code: "XCD" },
  DO: {
    label: "Dominican Republic",
    calling_code: "+1849",
    currency_code: "DOP",
  },
  TP: { label: "East Timor", calling_code: "+670", currency_code: "USD" },
  EC: { label: "Ecuador", calling_code: "+593", currency_code: "ECS" },
  EG: { label: "Egypt", calling_code: "+20", currency_code: "EGP" },
  SV: { label: "El Salvador", calling_code: "+503", currency_code: "SVC" },
  GQ: {
    label: "Equatorial Guinea",
    calling_code: "+240",
    currency_code: "XAF",
  },
  ER: { label: "Eritrea", calling_code: "+291", currency_code: "ERN" },
  EE: { label: "Estonia", calling_code: "+372", currency_code: "EUR" },
  ET: { label: "Ethiopia", calling_code: "+251", currency_code: "ETB" },
  FK: { label: "Falkland Islands", calling_code: "+500", currency_code: "FKP" },
  FO: { label: "Faroe Islands", calling_code: "+298", currency_code: "DKK" },
  FJ: { label: "Fiji Islands", calling_code: "+679", currency_code: "FJD" },
  FI: { label: "Finland", calling_code: "+358", currency_code: "EUR" },
  FR: { label: "France", calling_code: "+33", currency_code: "EUR" },
  GF: { label: "French Guiana", calling_code: "+594", currency_code: "EUR" },
  PF: { label: "French Polynesia", calling_code: "+689", currency_code: "XPF" },
  TF: {
    label: "French Southern territories",
    calling_code: "+262",
    currency_code: "EUR",
  },
  GA: { label: "Gabon", calling_code: "+241", currency_code: "XAF" },
  GM: { label: "Gambia", calling_code: "+220", currency_code: "GMD" },
  GE: { label: "Georgia", calling_code: "+995", currency_code: "GEL" },
  DE: { label: "Germany", calling_code: "+49", currency_code: "EUR" },
  GH: { label: "Ghana", calling_code: "+233", currency_code: "GHS" },
  GI: { label: "Gibraltar", calling_code: "+350", currency_code: "GIP" },
  GR: { label: "Greece", calling_code: "+30", currency_code: "EUR" },
  GL: { label: "Greenland", calling_code: "+299", currency_code: "DKK" },
  GD: { label: "Grenada", calling_code: "+1473", currency_code: "XCD" },
  GP: { label: "Guadeloupe", calling_code: "+590", currency_code: "EUR" },
  GU: { label: "Guam", calling_code: "+1671", currency_code: "USD" },
  GT: { label: "Guatemala", calling_code: "+502", currency_code: "QTQ" },
  GG: { label: "Guernsey", calling_code: "+undefined" },
  GN: { label: "Guinea", calling_code: "+224", currency_code: "GNF" },
  GW: { label: "Guinea-Bissau", calling_code: "+245", currency_code: "CFA" },
  GY: { label: "Guyana", calling_code: "+592", currency_code: "GYD" },
  HT: { label: "Haiti", calling_code: "+509", currency_code: "HTG" },
  HM: {
    label: "Heard Island and McDonald Islands",
    calling_code: "+672",
    currency_code: "AUD",
  },
  VA: {
    label: "Holy See (Vatican City State)",
    calling_code: "+379",
    currency_code: "EUR",
  },
  HN: { label: "Honduras", calling_code: "+504", currency_code: "HNL" },
  HK: { label: "Hong Kong", calling_code: "+852", currency_code: "HKD" },
  HU: { label: "Hungary", calling_code: "+36", currency_code: "HUF" },
  IS: { label: "Iceland", calling_code: "+354", currency_code: "ISK" },
  IN: { label: "India", calling_code: "+91", currency_code: "INR" },
  ID: { label: "Indonesia", calling_code: "+62", currency_code: "IDR" },
  IR: { label: "Iran", calling_code: "+98", currency_code: "IRR" },
  IQ: { label: "Iraq", calling_code: "+964", currency_code: "IQD" },
  IE: { label: "Ireland", calling_code: "+353", currency_code: "EUR" },
  IM: { label: "Isle of Man", calling_code: "+undefined" },
  IL: { label: "Israel", calling_code: "+972", currency_code: "ILS" },
  IT: { label: "Italy", calling_code: "+39", currency_code: "EUR" },
  CI: { label: "Ivory Coast", calling_code: "+225", currency_code: "XOF" },
  JM: { label: "Jamaica", calling_code: "+1876", currency_code: "JMD" },
  JP: { label: "Japan", calling_code: "+81", currency_code: "JPY" },
  JE: { label: "Jersey", calling_code: "+undefined" },
  JO: { label: "Jordan", calling_code: "+962", currency_code: "JOD" },
  KZ: { label: "Kazakhstan", calling_code: "+7", currency_code: "KZT" },
  KE: { label: "Kenya", calling_code: "+254", currency_code: "KES" },
  KI: { label: "Kiribati", calling_code: "+686", currency_code: "AUD" },
  KW: { label: "Kuwait", calling_code: "+965", currency_code: "KWD" },
  KG: { label: "Kyrgyzstan", calling_code: "+996", currency_code: "KGS" },
  LA: { label: "Laos", calling_code: "+856", currency_code: "LAK" },
  LV: { label: "Latvia", calling_code: "+371", currency_code: "LVL" },
  LB: { label: "Lebanon", calling_code: "+961", currency_code: "LBP" },
  LS: { label: "Lesotho", calling_code: "+266", currency_code: "LSL" },
  LR: { label: "Liberia", calling_code: "+231", currency_code: "LRD" },
  LY: {
    label: "Libyan Arab Jamahiriya",
    calling_code: "+218",
    currency_code: "LYD",
  },
  LI: { label: "Liechtenstein", calling_code: "+423", currency_code: "CHF" },
  LT: { label: "Lithuania", calling_code: "+370", currency_code: "LTL" },
  LU: { label: "Luxembourg", calling_code: "+352", currency_code: "EUR" },
  MO: { label: "Macao", calling_code: "+853" },
  MK: { label: "North Macedonia", calling_code: "+389", currency_code: "MKD" },
  MG: { label: "Madagascar", calling_code: "+261", currency_code: "MGF" },
  MW: { label: "Malawi", calling_code: "+265", currency_code: "MWK" },
  MY: { label: "Malaysia", calling_code: "+60", currency_code: "MYR" },
  MV: { label: "Maldives", calling_code: "+960", currency_code: "MVR" },
  ML: { label: "Mali", calling_code: "+223", currency_code: "XOF" },
  MT: { label: "Malta", calling_code: "+356", currency_code: "EUR" },
  MH: { label: "Marshall Islands", calling_code: "+692", currency_code: "USD" },
  MQ: { label: "Martinique", calling_code: "+596", currency_code: "EUR" },
  MR: { label: "Mauritania", calling_code: "+222", currency_code: "MRO" },
  MU: { label: "Mauritius", calling_code: "+230", currency_code: "MUR" },
  YT: { label: "Mayotte", calling_code: "+262", currency_code: "EUR" },
  MX: { label: "Mexico", calling_code: "+52", currency_code: "MXN" },
  FM: {
    label: "Micronesia, Federated States of",
    calling_code: "+691",
    currency_code: "USD",
  },
  MD: { label: "Moldova", calling_code: "+373", currency_code: "MDL" },
  MC: { label: "Monaco", calling_code: "+377", currency_code: "EUR" },
  MN: { label: "Mongolia", calling_code: "+976", currency_code: "MNT" },
  ME: { label: "Montenegro", calling_code: "+undefined" },
  MS: { label: "Montserrat", calling_code: "+1664", currency_code: "XCD" },
  MA: { label: "Morocco", calling_code: "+212", currency_code: "MAD" },
  MZ: { label: "Mozambique", calling_code: "+258", currency_code: "MZN" },
  MM: { label: "Myanmar", calling_code: "+95", currency_code: "MMR" },
  NA: { label: "Namibia", calling_code: "+264", currency_code: "NAD" },
  NR: { label: "Nauru", calling_code: "+674", currency_code: "AUD" },
  NP: { label: "Nepal", calling_code: "+977", currency_code: "NPR" },
  NL: { label: "Netherlands", calling_code: "+31", currency_code: "EUR" },
  AN: {
    label: "Netherlands Antilles",
    calling_code: "+599",
    currency_code: "ANG",
  },
  NC: { label: "New Caledonia", calling_code: "+687", currency_code: "XPF" },
  NZ: { label: "New Zealand", calling_code: "+64", currency_code: "NZD" },
  NI: { label: "Nicaragua", calling_code: "+505", currency_code: "NIO" },
  NE: { label: "Niger", calling_code: "+227", currency_code: "XOF" },
  NG: { label: "Nigeria", calling_code: "+234", currency_code: "NGN" },
  NU: { label: "Niue", calling_code: "+683", currency_code: "NZD" },
  NF: { label: "Norfolk Island", calling_code: "+672", currency_code: "AUD" },
  KP: { label: "North Korea", calling_code: "+850", currency_code: "KPW" },
  GB: { label: "Northern Ireland", calling_code: "+44", currency_code: "GBP" },
  MP: {
    label: "Northern Mariana Islands",
    calling_code: "+1670",
    currency_code: "USD",
  },
  NO: { label: "Norway", calling_code: "+47", currency_code: "NOK" },
  OM: { label: "Oman", calling_code: "+968", currency_code: "OMR" },
  PK: { label: "Pakistan", calling_code: "+92", currency_code: "PKR" },
  PW: { label: "Palau", calling_code: "+680", currency_code: "USD" },
  PS: { label: "Palestine", calling_code: "+970", currency_code: null },
  PA: { label: "Panama", calling_code: "+507", currency_code: "PAB" },
  PG: { label: "Papua New Guinea", calling_code: "+675", currency_code: "PGK" },
  PY: { label: "Paraguay", calling_code: "+595", currency_code: "PYG" },
  PE: { label: "Peru", calling_code: "+51", currency_code: "PEN" },
  PH: { label: "Philippines", calling_code: "+63", currency_code: "PHP" },
  PN: { label: "Pitcairn", calling_code: "+64" },
  PL: { label: "Poland", calling_code: "+48", currency_code: "PLN" },
  PT: { label: "Portugal", calling_code: "+351", currency_code: "EUR" },
  PR: { label: "Puerto Rico", calling_code: "+1939", currency_code: "USD" },
  QA: { label: "Qatar", calling_code: "+974", currency_code: "QAR" },
  RE: { label: "Reunion", calling_code: "+262", currency_code: "EUR" },
  RO: { label: "Romania", calling_code: "+40", currency_code: "RON" },
  RU: { label: "Russian Federation", calling_code: "+7", currency_code: "RUB" },
  RW: { label: "Rwanda", calling_code: "+250", currency_code: "RWF" },
  SH: { label: "Saint Helena", calling_code: "+290", currency_code: "SHP" },
  KN: {
    label: "Saint Kitts and Nevis",
    calling_code: "+1869",
    currency_code: "XCD",
  },
  LC: { label: "Saint Lucia", calling_code: "+1758", currency_code: "XCD" },
  PM: {
    label: "Saint Pierre and Miquelon",
    calling_code: "+508",
    currency_code: "EUR",
  },
  VC: {
    label: "Saint Vincent and the Grenadines",
    calling_code: "+1784",
    currency_code: "XCD",
  },
  WS: { label: "Samoa", calling_code: "+685", currency_code: "WST" },
  SM: { label: "San Marino", calling_code: "+378", currency_code: "EUR" },
  ST: {
    label: "Sao Tome and Principe",
    calling_code: "+239",
    currency_code: "STD",
  },
  SA: { label: "Saudi Arabia", calling_code: "+966", currency_code: "SAR" },
  SN: { label: "Senegal", calling_code: "+221", currency_code: "XOF" },
  RS: { label: "Serbia", calling_code: "+381", currency_code: "RSD" },
  SC: { label: "Seychelles", calling_code: "+248", currency_code: "SCR" },
  SL: { label: "Sierra Leone", calling_code: "+232", currency_code: "SLL" },
  SG: { label: "Singapore", calling_code: "+65", currency_code: "SGD" },
  SK: { label: "Slovakia", calling_code: "+421", currency_code: "EUR" },
  SI: { label: "Slovenia", calling_code: "+386", currency_code: "EUR" },
  SB: { label: "Solomon Islands", calling_code: "+677", currency_code: "SBD" },
  SO: { label: "Somalia", calling_code: "+252", currency_code: "SOS" },
  ZA: { label: "South Africa", calling_code: "+27", currency_code: "ZAR" },
  GS: {
    label: "South Georgia and the South Sandwich Islands",
    calling_code: "+500",
    currency_code: "GBP",
  },
  KR: { label: "South Korea", calling_code: "+82", currency_code: "KRW" },
  SS: { label: "South Sudan", calling_code: "+211", currency_code: "SSP" },
  ES: { label: "Spain", calling_code: "+34", currency_code: "EUR" },
  LK: { label: "Sri Lanka", calling_code: "+94", currency_code: "LKR" },
  SD: { label: "Sudan", calling_code: "+249", currency_code: "SDG" },
  SR: { label: "Suriname", calling_code: "+597", currency_code: "SRD" },
  SJ: {
    label: "Svalbard and Jan Mayen",
    calling_code: "+47",
    currency_code: "NOK",
  },
  SZ: { label: "Swaziland", calling_code: "+268", currency_code: "SZL" },
  SE: { label: "Sweden", calling_code: "+46", currency_code: "SEK" },
  CH: { label: "Switzerland", calling_code: "+41", currency_code: "CHF" },
  SY: { label: "Syria", calling_code: "+963", currency_code: "SYP" },
  TJ: { label: "Tajikistan", calling_code: "+992", currency_code: "TJS" },
  TZ: { label: "Tanzania", calling_code: "+255", currency_code: "TZS" },
  TH: { label: "Thailand", calling_code: "+66", currency_code: "THB" },
  CD: {
    label: "The Democratic Republic of Congo",
    calling_code: "+243",
    currency_code: "CDF",
  },
  TL: { label: "Timor-Leste", calling_code: "+undefined" },
  TG: { label: "Togo", calling_code: "+228", currency_code: "XOF" },
  TK: { label: "Tokelau", calling_code: "+690", currency_code: "NZD" },
  TO: { label: "Tonga", calling_code: "+676", currency_code: "TOP" },
  TT: {
    label: "Trinidad and Tobago",
    calling_code: "+1868",
    currency_code: "TTD",
  },
  TN: { label: "Tunisia", calling_code: "+216", currency_code: "TND" },
  TR: { label: "Turkey", calling_code: "+90", currency_code: "TRY" },
  TM: { label: "Turkmenistan", calling_code: "+993", currency_code: "TMT" },
  TC: {
    label: "Turks and Caicos Islands",
    calling_code: "+1649",
    currency_code: "USD",
  },
  TV: { label: "Tuvalu", calling_code: "+688", currency_code: "AUD" },
  UG: { label: "Uganda", calling_code: "+256", currency_code: "UGX" },
  UA: { label: "Ukraine", calling_code: "+380", currency_code: "UAH" },
  AE: {
    label: "United Arab Emirates",
    calling_code: "+971",
    currency_code: "AED",
  },
  UK: { label: "United Kingdom", calling_code: "+44", currency_code: "GBP" },
  US: { label: "United States", calling_code: "+1", currency_code: "USD" },
  UM: {
    label: "United States Minor Outlying Islands",
    calling_code: "+1",
    currency_code: "USD",
  },
  UY: { label: "Uruguay", calling_code: "+598", currency_code: "UYU" },
  UZ: { label: "Uzbekistan", calling_code: "+998", currency_code: "UZS" },
  VU: { label: "Vanuatu", calling_code: "+678", currency_code: "VUV" },
  VE: { label: "Venezuela", calling_code: "+58", currency_code: "VEF" },
  VN: { label: "Vietnam", calling_code: "+84", currency_code: "VND" },
  VG: {
    label: "Virgin Islands, British",
    calling_code: "+1",
    currency_code: "USD",
  },
  VI: {
    label: "Virgin Islands, U.S.",
    calling_code: "+1",
    currency_code: "USD",
  },
  WF: {
    label: "Wallis and Futuna",
    calling_code: "+681",
    currency_code: "XPF",
  },
  EH: { label: "Western Sahara", calling_code: "+212", currency_code: "MAD" },
  YE: { label: "Yemen", calling_code: "+967", currency_code: "YER" },
  ZM: { label: "Zambia", calling_code: "+260", currency_code: "ZMW" },
  ZW: { label: "Zimbabwe", calling_code: "+263", currency_code: "ZWD" },
};


export const getCountriesDisplayList = () => {
  let countries = [];

  Object.keys(countriesObj || {}).forEach((code) => {
    countries.push({
      id: code,
      label: countriesObj[code].label
    })
  })

  return countries;
}

